import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ModalFooter, Spinner } from 'reactstrap';
import swal from 'sweetalert';
import { axiosGet, axiosPost } from '../../../utils/AxiosApi';
import { URL } from '../../../utils/Constants';
import PrimaryButton from '../../Country/ButtonWindow';
import { Navbar, NavbarBrand } from 'reactstrap';
import logo from '../../../assets/images/new4extrasmall.png';
import '../../../assets/scss/navHeader.scss';
import '../../../assets/scss/updatePassword.scss';
import { Link } from 'react-router-dom';
import { timezoneStringHandler } from '../../../utils/Utils';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class UpdatePassword extends Component {
  state = {
    password: '',
    cpassword: '',
    submitSpinner: false,
    validationMessage: 0,
    decryptedTimestamp: '',
    isredirect: false,
    currentTimeState: '',
    ActualTimeState: '',
    showPassword: false,
    showPasswordConfirm: false
  };

  componentDidMount() {
    this.updatePasswordCheck();
    const timeStamp = this.props.history.location.pathname.split('/')[3];
    const decodedTimestamp = atob(timeStamp);
    const actualTimestamp = atob(decodedTimestamp);
    this.setState({ ActualTimeState: actualTimestamp });

    const dateNow = new Date();
    const currentTime = dateNow.toISOString().slice(0, 19).replace('T', ' ');
    this.setState({ currentTimeState: currentTime });
    if (this.state.currentTimeState > this.state.ActualTimeState) {
      const redirect = this.setState({ isredirect: true });
      swal({
        title: 'Error',
        text: 'Reset Password Link is Expired',
        icon: 'error'
      }).then((redirect) => {
        if (redirect) {
          window.close();
          window.open(`/login`, '_blank');
        }
      });
    }
  }

  handlePasswordChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
      if (strongRegex.test(this.state.password)) {
        this.setState({
          validationMessage: 1
        });
      } else {
        this.setState({
          validationMessage: 0
        });
      }
    });
  };

  handlePasswordConfirmationChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
      if (strongRegex.test(this.state.cpassword)) {
        this.setState({
          validationMessage: 1
        });
      } else {
        this.setState({
          validationMessage: 0
        });
      }
    });
  };

  updatePassword = () => {
    if (this.state.password !== '' && this.state.cpassword !== '') {
      this.setState({ submitSpinner: true }, () => {
        const data = {
          username: this.props.match.params.uname,
          password: this.state.password,
          confirmPassword: this.state.cpassword
        };
        if (this.state.currentTimeState > this.state.ActualTimeState) {
          const redirect = this.setState({ isredirect: true });
          swal({
            title: 'Error',
            text: 'Reset Password Link is Expired',
            icon: 'error'
          }).then((redirect) => {
            if (redirect) {
              window.close();
              window.open(`/login`, '_blank');
            }
          });
        }

        if (this.state.validationMessage === 1) {
          if (data.password === data.confirmPassword) {
            axiosPost(URL.updatePassword, data, (response) => {
              if (response.data.success) {
                this.setState({
                  password: '',
                  cpassword: ''
                });
                this.setState({ submitSpinner: false });
                // swal("success", "Password updated successfully.", "success");
                // History.push(`${process.env.PUBLIC_URL}/login`);

                swal({
                  title: 'Password changed successfully!!',
                  text: 'Your password in the system has been replaced with new password. Please proceed to login with new password.',
                  icon: 'success'
                  // confirmButtonText: "Proceed to Login",
                }).then((deleteConfirm) => {
                  if (deleteConfirm) {
                    window.close();
                    window.open(`/login`, '_blank');
                  }
                });
              } else {
                this.setState({ submitSpinner: false });
                swal('Error', 'Your request was unsuccessful. Please try again later.', 'error');
                return;
              }
            });
          } else {
            this.setState({ submitSpinner: false });
            swal('Warning', "Password and Password Confirmation didn't match!", 'warning');
          }
        } else {
          this.setState({ submitSpinner: false });
          swal('Warning', 'Password requirement not met!', 'warning');
        }
      });
    } else {
      swal('Error', 'Enter valid password. Passowrd cannot be empty.', 'error');
      return;
    }
  };

  updatePasswordCheck = () => {
    const data = this.props.history.location.pathname.split('/')[2];
    axiosGet(`${URL.forgotPasswordUrlCheck}/${data}`, (response) => {
      if (response && !response.data.result) {
        swal({
          title: 'Error',
          text: 'Forget Password Link is expired!',
          icon: 'error'
        }).then((redirectConfirm) => {
          if (redirectConfirm) {
            window.close();
            window.open(`/login`, '_blank');
          }
        });
      }
    });
  };


  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };

  handleTogglePasswordConfirm = () => {
    this.setState((prevState) => ({
      showPasswordConfirm: !prevState.showPasswordConfirm
    }));
  };

  render() {
    return (
      <>
        <div className="bg-full-cover">
          <div className="container-fluid">
            {/* navbar start */}
            <div className="row mb-5">
              <div className="col pl-0 pr-0">
                <Navbar color="faded" light>
                  <div className="col text-center" style={{ position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0 }}>
                      <Link to={'/'}>
                        <NavbarBrand className="mr-auto">
                          <img
                            src={logo}
                            className="img-fluid"
                            alt="img"
                            style={{ height: '40px' }}
                          />
                        </NavbarBrand>
                      </Link>
                    </span>
                    <div className="customerKycHeader">Reset Password</div>
                  </div>
                </Navbar>
              </div>
            </div>
            {/* navbar end */}

            <div className="container mt-5 update-password-div">
              {this.state.submitSpinner ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: '16px', marginTop: '15px' }}>
                    Please Wait. Updating Password ...
                  </div>
                </div>
              ) : null}

              <div style={{ padding: '20px 5px 0px 5px' }}>
                <form>
                  <div className="form-group mb-0 password-input-wrapper">
                    <label>Password:</label>
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      placeholder="Enter a new password"
                      className="form-control inputColor"
                      name="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                      autoFocus
                    />
                    <span className="password-toggle-reset" onClick={this.handleTogglePassword}>
                      <FontAwesomeIcon
                        icon={this.state.showPassword ? faEyeSlash : faEye}
                        size="lg"
                      />
                    </span>
                  </div>
                  {this.state.validationMessage === 0 ? (
                    <div className="pass-error-warning">
                      <strong>Password must contain:</strong>
                      <br />
                      - at least 1 lowercase alphabetical character
                      <br />
                      - at least 1 uppercase alphabetical character
                      <br />
                      - must contain at least 1 numeric character
                      <br />- eight characters or longer
                    </div>
                  ) : null}
                  <div className="form-group mt-3 password-input-wrapper">
                    <label>Password Confirmation:</label>
                    <input
                      type={this.state.showPasswordConfirm ? 'text' : 'password'}
                      placeholder="Enter Password Confirmation"
                      className="form-control inputColor"
                      name="cpassword"
                      value={this.state.cpassword}
                      onChange={this.handlePasswordConfirmationChange}
                    />
                    <span className="password-toggle-reset" onClick={this.handleTogglePasswordConfirm}>
                      <FontAwesomeIcon
                        icon={this.state.showPasswordConfirm ? faEyeSlash : faEye}
                        size="lg"
                      />
                    </span>
                  </div>
                  {this.state.password !== null &&
                    this.state.cpassword !== null &&
                    this.state.password !== this.state.cpassword ? (
                    <div className="pass-error-warning">
                      <strong>Password is not same</strong>
                    </div>
                  ) : null}
                </form>
                <ModalFooter>
                  <PrimaryButton handleClick={this.updatePassword} buttonTitle="Update" />
                </ModalFooter>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UpdatePassword);
