export function permissionCheck(activity, moduleName) {
  if (!moduleName) return false;

  const modulesPermissions = JSON.parse(localStorage.getItem('modulesPermissions')) || [];

  return modulesPermissions.some((permission) => permission.name === `${activity}|${moduleName}`);
}

export function permissionCheckActivity(activity) {
  if (!activity) return false;

  const modulesPermissions = JSON.parse(localStorage.getItem('modulesPermissions')) || [];

  return modulesPermissions.some((el) => activity.includes(el.name));
}

export function permissionRateWizardCheck(name) {
  const modulesPermissions = JSON.parse(localStorage.getItem('modulesPermissions')) || [];

  return modulesPermissions.some((el) => el.name === name);
}

// export function checkPermissionForModules(moduleName) {
//   const modulesPermissions =
//     JSON.parse(localStorage.getItem("modulesPermissions")) || [];

//   return !!modulesPermissions.find((permission) => {
//     let permissionArr = permission?.name?.split("|");
//     if (permissionArr && permissionArr[1]) {
//       return permissionArr[1] === moduleName;
//     }
//     return false;
//   });
// }

export function checkPermissionForModules(moduleName) {
  const modulesPermissions = JSON.parse(localStorage.getItem('modulesPermissions')) || [];

  return !!modulesPermissions.find((permission) => permission.name.includes(moduleName));
}

const permissionMappings = {
  'view-all-customer-query-sales|Rate Wizard': 'response-all-customer-query-sales',
  'view-customer-query-sales|Rate Wizard': 'response-customer-query-sales',
  'view-air-query-pricing|moduleName': 'response-air-query-pricing',
  'view-sea-query-pricing|moduleName': 'response-sea-query-pricing'
};

export const checkResponsePermission = (selectedQuery, moduleName) => {
  const permissionsToLookFor = [
    {
      query: 'view-all-customer-query-sales|Rate Wizard',
      permission: 'response-all-customer-query-sales',
      module: 'Rate Wizard'
    },
    {
      query: 'view-customer-query-sales|Rate Wizard',
      permission: 'response-customer-query-sales',
      module: 'Rate Wizard'
    },
    {
      query: 'view-air-query-pricing|' + moduleName,
      permission: 'response-air-query-pricing',
      module: moduleName
    },
    {
      query: 'view-sea-query-pricing|' + moduleName,
      permission: 'response-sea-query-pricing',
      module: moduleName
    },
    {
      query: 'both|' + moduleName,
      permission: 'response-sea-query-pricing',
      module: moduleName
    },
    {
      query: 'both|' + moduleName,
      permission: 'response-air-query-pricing',
      module: moduleName
    }

  
  ];
  const permission = permissionsToLookFor.find((perm) => perm.query === selectedQuery);
  if (permission)
    if (permissionCheck(permission.permission, permission.module)) return true;
    else return false;
  else return false;
};

export const checkCustomerEnquiriesResponsePermission = (myCustomerEnquiry, module) => {
  const permissionName = myCustomerEnquiry
    ? 'response-customer-query-sales'
    : 'response-all-customer-query-sales';

  return permissionCheck(permissionName, module);
};

export const checkPricingPermission = (selectedQuery) => {
  const permissionsToLookFor = [
    {
      query: `view-air-query-pricing|Pricing`,
      permission: 'view-air-query-pricing',
      module: 'Pricing'
    },
    {
      query: `view-sea-query-pricing|Pricing`,
      permission: 'view-sea-query-pricing',
      module: 'Pricing'
    }
  ];

  const permission = permissionsToLookFor.find((perm) => perm.query === selectedQuery);

  return permission ? permissionCheck(permission.permission, permission.module) : false;
};

export const isPricingSuperUser = () => {
  return permissionCheck(['pricing-super-user'], 'Pricing');
};

export const isSalesSuperUser = () => {
  return permissionCheck(['sales-super-user'], 'Customer Enquiries');
};
