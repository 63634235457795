import axios from 'axios';
import swal from 'sweetalert';
import { headers, URL, serverLocation } from './Constants';
import history from './History';

const refreshHeaders = {
  headers: {
    refresh_token: `${localStorage.getItem('refreshToken')}`
  }
};

const requestMap = new Map();

axios.interceptors.request.use(
  (config) => {
    let key = '';
    const segments = config?.url.split('/');
    if (/^\d+$/.test(segments && segments.length && segments[segments.length - 1])) {
      key = segments.slice(0, -1).join('/');
    } else {
      key = config.url;
    }

    if (requestMap.has(key)) {
      const cancelToken = requestMap.get(key);
      cancelToken.cancel('Duplicate Request');
    }
    const cancelToken = axios.CancelToken.source();
    config.cancelToken = cancelToken.token;
    requestMap.set(key, cancelToken);

    const token = localStorage.getItem('token');
    const refresh_token = localStorage.getItem('refreshToken');
    if (token) {
      if (config.url === `${serverLocation}/refreshToken`) {
        config.headers['refresh_token'] = refresh_token;
        config.headers['Authorization'] = null;
      } else {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response?.message === 'Duplicate Request') {
      return response;
    }

    let key = '';
    const segments = response?.config?.url?.split('/');
    if (/^\d+$/.test(segments && segments.length && segments[segments.length - 1])) {
      key = segments.slice(0, -1).join('/');
    }
    requestMap.delete(key);
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error?.message === 'Duplicate Request') {
      console.log(error.message);
      return Promise.reject(error);
    }

    let key = '';
    const segments = error?.config?.url?.split('/');

    if (/^\d+$/.test(segments && segments.length && segments[segments.length - 1])) {
      key = segments.slice(0, -1).join('/');
    }
    requestMap.delete(key);

    //log out if refresh token fetch is unsuccessfull by status 500
    if (
      error.response &&
      error.response.status === 500 &&
      originalRequest.url === `${serverLocation}/refreshToken`
    ) {
      localStorage.clear();
      history.push(`/`);
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url !== `${serverLocation}/api/login` &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .post(`${serverLocation}/refreshToken`, {}, refreshHeaders)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('token', res.data.access_token);
            localStorage.setItem('refreshToken', res.data.refresh_token);
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + localStorage.getItem('token');
          } else {
            localStorage.clear();
            history.push('/');
          }
        })
        .catch((err) => {
          localStorage.clear();
          swal(
            'Session Expired',
            'Your session has expired. You will be logged out.',
            'info'
          );
          history.push('/');
          setTimeout(() => window.location.reload(), [3000]);
        });
    }
    return Promise.reject(error);
  }
);

export const axiosGet = (url, successCallback, failureCallback) => {
  axios
    .get(url, { headers })
    .then(
      successCallback ||
      function (response) {
        swal('Success', response.message, 'success');
      }
    )
    .catch(
      failureCallback ||
      function (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          history.push(`/`);
          swal(
            'Session Expired',
            'Your session has expired. You will be logged out.',
            'info'
          );
          setTimeout(() => window.location.reload(), [3000]);
        } else {
          return error;
        }
      }
    );
};

export const axiosGetV2 = async (url) => {
  try {
    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    if (error.response) {
      return false;
    }
  }
};

export const axiosPost = (url, params, successCallback, failureCallback) => {
  let doPost = '';
  if (url !== URL.loginUrl) {
    doPost = axios.post(url, params, { headers });
  } else {
    doPost = axios.post(url, params);
  }
  doPost
    .then(
      successCallback ||
      function (response) {
        swal('Success', response.data.message, 'success');
      }
    )
    .catch(
      failureCallback ||
      function (error) {
        if (error.response?.status === 401) {
          localStorage.clear();
          history.push(`/`);
          swal(
            'Session Expired',
            'Your session has expired. You will be logged out.',
            'info'
          );
          setTimeout(() => window.location.reload(), [3000]);
        } else {
          if (error?.message === 'Duplicate Request') {
            console.log(error.message);
            return;
          }
          let errorResponse = error.response ? error.response.data : error;
          let errMessage = '';
          if (errorResponse.errors && errorResponse.errors.length > 0) {
            errMessage = errorResponse.errors[0].defaultMessage;
          } else {
            errMessage = errorResponse?.data?.name[0];
          }
          if (errorResponse) {
            console.log(errorResponse);
          }
          errorResponse &&
            swal(
              errorResponse.error || errorByStatusMessages[error.response?.status],
              errMessage !== '' ? errMessage : errorResponse.message,
              'error'
            );
        }
      }
    );
};

export const axiosDelete = (url, successCallback, failureCallback) => {
  axios
    .delete(url, { headers })
    .then(
      successCallback ||
      function (response) {
        swal('Success', response.message, 'success');
      }
    )
    .catch(
      failureCallback ||
      function (error) {
        let errorResponse = error.response ? error.response.data : error;
        swal(errorResponse.error || 'Forbidden Deletion', errorResponse.message, 'error');
      }
    );
};

export const axiosPut = (url, params, successCallback, failureCallback) => {
  axios
    .put(url, params, { headers })
    .then(
      successCallback ||
      function (response) {
        swal('Success', response.message, 'success');
      }
    )
    .catch(
      failureCallback ||
      function (error) {
        let errorResponse = error.response ? error.response.data : error;
        swal(errorResponse.error || 'Update Forbidden', errorResponse.message, 'error');
      }
    );
};

export const emailValidateApi = async (url, params) => {
  try {
    const response = await axios.post(url, params);
    console.log(response.data);
    return response.data.success;
  } catch (error) {
    if (error.response) {
      return false;
    }
  }
};

// for multiple email validation
export const emailValidateMultipleApi = async (url, params) => {
  try {
    const response = await axios.post(url, params);
    return response?.data;
  } catch (error) {
    return false;
  }
};

const errorByStatusMessages = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment_Required',
  403: 'Forbidden',
  404: 'Not_Found',
  405: 'Method_Not_Allowed',
  406: 'Not_Acceptable',
  407: 'Authentication_Required',
  408: 'Request_Timeout',
  500: 'Internal_Server_Error',
  501: 'Not_Implemented',
  502: 'Bad_Gateway',
  503: 'Service_Unavailable',
  504: 'Gateway_Timeout'
};
